.appContainer {
  width: 100%;
  height: 100%;
}

input:-internal-autofill-selected {
  background-color: #ffffff !important;
}

.confirmMessage {
  font-size: 13px;
  color: #282828;
  span {
    color: #dc283d
  }
}

.cropImage {
  position: relative;
  .Loading__bar___21yOt {
    position: absolute;
    top: 0;
    left: 0;
  }
  .Loading__spinner___11Pm4 {
    position: absolute;
    top: 8px;
    left: 5px;
  }
}

.DayPicker {
  box-shadow: none;
}
.DayPickerKeyboardShortcuts_buttonReset {
  display: none;
}
.DateRangePicker_picker {
  top: 0px !important;
}
@media (max-width: 767px) {
  .DayPicker {
    margin: 0 20px;
  }
}

.photos {
  position: relative;
  .Loading__bar___21yOt {
    position: absolute;
    top: 0;
    left: 0;
  }
  .Loading__spinner___11Pm4 {
    position: absolute;
    top: 8px;
    left: 5px;
  }
}

.table {
  width: calc(100% - 4px);
  background-color: #ffffff;
  table {
    margin-bottom: 0;
  }
  // thead {
  //   position: sticky;
  //   top: 60px;
  // }
  .tbHead {
    background-color: #F3F4F7;
    
    th {
      font-size: 14px;
      font-weight: 700;
      line-height: 1.27;
      padding: 20px;
      vertical-align: top;
      min-width: 100px;
      color: #333333;
      cursor: pointer;
      position: relative;
      white-space: nowrap;
    }
    .icon {
      vertical-align: top;
      margin-left: 10px;
      opacity: 0.3;
    }
    .iconActive {
      opacity: 1;
    }
    .unit {
      margin-bottom: 0;
      font-size: 12px;
      color: #919b8e;
      text-align: center;
      font-weight: normal;
      margin-top: 4px;
      text-align: right;
    }
  }
  .tbRow {
    border-bottom: 1px solid rgba(9,38,49,0.1);
    cursor: pointer;
    td {
      font-size: 14px;
      line-height: 19px;
      padding: 17px 20px;
      font-weight: normal;
      color: #767676;
    }
  
    &:hover {
      box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.16);
      // border: solid 1px #f7f9fb;
      background-color: #f7f9fb;
    }
  }

  .mw250 {
    max-width: 250px;
  }
  .mw300 {
    max-width: 300px;
  }
}

.mt10 {
  margin-top: 10px;
}

.tableGray {
  .thead {
    background: #dadee3;
    th {
      font-size: 13px;
      font-weight: normal;
      text-align: center;
      vertical-align: middle !important;
      min-width: 70px;
      padding: 8px;
      font-weight: normal;
      border: 1px solid #c4c9d0;
      color: #333;
    }
  }
  
  .tbody {
    background: #ffffff;
    td {
      font-size: 13px;
      position: relative;
      vertical-align: middle;
      min-width: 70px;
      padding: 8px;
      font-weight: 500;
      border: 1px solid #c4c9d0;
      color: #333;
    }
    .largeValue {
      font-size: 16px;
    }
  }
}

.tbAction {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
  .btnRemove {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
  }
  .editIcon {
    width: 26px;
    color: #8be9fd;
  }
}

.recharts-text {
  font-size: 13px;
}

.orderSelectField {
  height: 32px !important;
  background-color: #ffffff !important;
  border: solid 1px #BFC7CA !important;
  border-radius: 4px;
  font-size: 12px !important;
}
.customSearchInput {
  input {
    height: 30px !important;
    border-radius: 4px;
    border: solid 1px #BFC7CA !important;
    background-color: #ffffff !important;
    font-size: 12px !important;
    padding-left: 38px !important;
  }
  a {
    top: 4px !important;
    svg {
      width: 14px !important;
    }
  }
}

.filterContainer {
  .dropdown {
    .dropdown-toggle {
      height: 40px;
      color: #092631;
      justify-content: space-between;
      padding: 0 16px;
      width: 100%;
      &:hover {
        color: #092631;
        background-color: #ffffff;
      }
    }
    .btn-success {
      color: #092631;
      background-color: #ffffff;
      border: solid 1px #BFC7CA;
    }

    @media (max-width: 767px) {
  }
  
}
}

.mb20 {
  margin-bottom: 20px;
}

.image-gallery-thumbnails-container {
  text-align: left !important;
}

.slick-list> div {
  margin-left:0;
}


.DateRangePickerInput_arrow {
  display: none;
}

.RangerDatePicker_datePickerWrapper__101PN .RangerDatePicker_dateTime__EhpD3 {
  margin-left: 0;
}

.rangerValue {
  .dropdown {
    width: 100%;
  }
}

.group {
  box-shadow: 0 0.46875rem 2.1875rem rgba(4,9,20,0.03), 0 0.9375rem 1.40625rem rgba(4,9,20,0.03), 0 0.25rem 0.53125rem rgba(4,9,20,0.05), 0 0.125rem 0.1875rem rgba(4,9,20,0.03);
  border-width: 0;
  transition: all .2s;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 30px;
}

.groupBody {
  padding: 20px;
}

.groupForm {
  .groupTitle {
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 0
  }
  .mb20 {
    margin-bottom: 20px;
  }
  .groupTitleLarge {
    font-size: 30px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 20px;
    text-align: center;
    text-transform: uppercase;
  }
  .mt20 {
    margin-top: 20px;
  }
  .fieldWrapper {
    display: flex;
    align-items: center;
    padding: 24px 0;
    border-bottom: 1px solid #dddddd;
    flex-flow: row wrap;
    .label {
      width: 250px;
      font-size: 14px;
      line-height: 1.25;
      color: #666666;
      margin-bottom: 0;
    }
    .mt0 {
      margin-top: 0;
    }
    .mt10 {
      margin-top: 10px;
    }
    .required {
      font-size: 16px;
      line-height: 1.25;
      color: #ff6565;
    }
    .largeLabel {
      width: 280px;
      font-size: 20px;
      color: #092631;
      margin-bottom: 0;
      font-weight: bold;
    }
    .smallLabel {
      font-size: 12px;
      line-height: 1.67;
      color: rgba(0,0,0,0.6)
    }
    .field {
      flex: 1;
      padding-right: 100px;
      max-width: 100%;
      .note {
        font-size: 13px;
        color: #666666;
        margin-bottom: 0;
      }
    }
    .row {
      display: flex;
      align-items: center;
      .fieldContainer {
        flex: 1
      }
      .unit {
        width: 160px;
        margin-left: 10px;
      }
    }
  }

  .start {
    align-items: flex-start;
    .label {
      margin-top: 6px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
  }

  .bb0 {
    border-bottom: 0;
  }

  @media (max-width: 767px) {
    padding: 20px;
    .fieldWrapper {
      flex-direction: column;
      padding: 16px 0;
      .label {
        width: 100%;
        margin-bottom: 10px;
      }
      .field {
        width: 100%;
        padding-right: 0;
      }
    }
  }
}

.ps__thumb-y {
  background-color: rgba(0,0,0,0.1) !important;
}

.mt20 {
  margin-top: 20px;
}

.images {
  .slick-dots {
    bottom: 7px;
  }
}

.slideDisabled {
  pointer-events: none;
  // background-color: #f1f1f1 !important;
}

.ml10 {
  margin-left: 10px; 
}

.prewrap {
  white-space: pre-wrap !important;
}
