.inputComponent {
  border: 1px dashed #BDBDBD;
  border-radius: 8px;
  background: #FAFBFC;
  padding: 32px;
  text-align: center;
  .imageIcon {
    margin-bottom: 10px;
    max-width: 100%;
  }
  .text {
    font-size: 16px;
    font-weight: bold;
    color: #585858;
    text-align: center;
    margin-bottom: 4px;
  }
  .or {
    font-size: 12px;
    color: #585858;
    text-align: center;
    margin-bottom: 4px;
  }
  .note {
    font-size: 12px;
    line-height: 16px;
    color: #949496;
  }
  .actions {
    display: flex;
    justify-content: center;
  }
}

.dropzone {
  border: 0;
  overflow: auto;
}




.files {
  width: 100%;
  .fileWrapper {
    padding: 10px 20px;
    border-bottom: 1px solid #dddddd;
  }
  .row {
    display: flex;
    align-items: center;
  }
  .left {
    display: flex;
    align-items: center;
  }
  .percent {
    font-size: 12px;
    font-weight: bold;
    color: #666666;
    margin-left: 10px;
    margin-bottom: 0;
    width: 30px;
    text-align: right;
  }
  .progressWrapper {
    flex: 1;
    height: 4px;
    border-radius: 2px;
    background-color: #dddddd;
    margin-top: 4px;
    .progress {
      height: 4px;
      background-color: #1f7da1;
      border-radius: 2px;
    }
  }
  .file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .loader {
      position: absolute;
      right: 50px;
      top: 50%;
      transform: translateY(-50%);
    }
    .fileName {
      font-size: 14px;
      color: #31708e;
      margin-bottom: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      margin-left: 10px;
    }
    .times {
      width: 14px;
    }
  }
}

.image {
  height: 100%;
  max-height: 300px;
  max-width: 100%;
  width: auto;
}

.prev {
  position: relative;
  .btnRemoveImage {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -30px;
    right: -30px;
    z-index: 99;
    &:hover {
      background-color: #f5f5f5;
    }
    .closeIcon {
      width: 18px;
      height: 18px;
    }
  }
}

.preview {
  position: relative;
  .loader {
    position: absolute;
    top: -28px;
    left: -28px;
  }
}
