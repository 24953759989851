.container {
  position: relative;
  .input {
    box-shadow: none !important;
    height: 40px;
    border-radius: 4px;
    border: solid 1px #BFC7CA;
    background-color: rgba(255, 255, 255, 0.25);
    font-size: 16px;
    color: #092631;
    outline: 0;
    width: 100%;
    padding: 0 20px 0 34px;
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #8d92a3;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: #8d92a3;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: #8d92a3;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: #8d92a3;
    }
  }
  .btnSearch {
    position: absolute;
    left: 10px;
    top: 10px;
  }
  .icon {
    width: 18px;
    font-size: 18px;
    color: #8d92a3;
  }
}

