.container {
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 18px;
      font-weight: bold;
      color: #aaaaaa;
      margin-bottom: 0;
      @media (max-width: 767px) {
        padding: 0 20px;
      }
    }
  }

  .row {
    display: flex;
    align-items: center;
  }

  .image {
    width: 80px;
    margin-right: 10px;
  }

  .name {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 6px;
  }

  .symbol {
    font-size: 11px;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  .message {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
            line-clamp: 3; 
    -webkit-box-orient: vertical;
    * {
      font-size: 14px !important;
      font-weight: normal;
    }
  }
  
  .filter {
    margin-bottom: 20px;
    width: 100%;
  }

}

a {
  color: #008a81;
  &:hover {
    color: #008a81;
  }
}

.coverLetter {
  max-width: 400px;
  overflow: hidden;
 text-overflow: ellipsis;
 display: -webkit-box;
 -webkit-line-clamp: 1; /* number of lines to show */
         line-clamp: 1; 
 -webkit-box-orient: vertical;
 * {
   font-size: 14px !important;
   font-weight: normal;
 }
}

.note {
  font-size: 12px;
  font-style: italic;
}

.btnEdit {
  border: 1px solid #666666;
  height: 36px;
  line-height: 36px;
  border-radius: 8px;
  white-space: nowrap;
  margin-bottom: 10px;
  margin-left: 10px;
  width: 110px;
  display: inline-block;
  font-size: 12px;
  text-align: center;
  background-color: transparent;
  color: #333333;
  font-weight: 600;
  &:hover {
    color: #333333;
    background-color: #f8f8f8;
  }
}

.link {
  display: block;
  margin-bottom: 6px;
  &:last-child {
    margin-bottom: 0;
  }
}

.label {
  font-size: 13px;
  color: #666666;
  margin-bottom: 10px;
  b {
    color: #333333;
    font-size: 14px;
    font-weight: normal;
  }
}
