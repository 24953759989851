.pagingWrapper {
  font-size: 12px;
  // margin-bottom: 16px;
  position: relative;
  // padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .row {
    display: flex;
    align-items: center; 
  }
  .note {
    position: absolute;
    right: 16px;
    top: 0;
    font-size: 12px;
    color: #4a4a4a;
    @media (max-width: 768px) {
      text-align: center;
      position: relative;
    }
  }

  .left {
    display: flex;
    align-items: center;
  }

  .currentPerTotal {
    font-size: 16px;
    font-weight: bold;
    color: #282828;
    line-height: 26px;
    margin-right: 20px;
  }

  .pageSize {
    display: flex;
    align-items: center;
    margin-right: 10px;
    
    .label {
      font-size: 14px;
      line-height: 19px;
      color: #585858;
      margin-bottom: 0;
      margin-right: 10px;
    }
    .selectPageSize {
      width: 60px;
    }
  }

  .divider {
    width: 1px;
    height: 20px;
    background-color: #E3E3E3;
    margin-right: 10px;
  }
  
  .page {
    text-align: center;
    display: flex;
    align-items: center;
    .pageNumber {
      width: 32px;
      height: 32px;
      background: #FFFFFF;
      border: 1px solid #DFE3E8;
      box-sizing: border-box;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      font-size: 14px;
      color: #585858;
      cursor: pointer;
    }
    .active {
      color: #D0B674;
      border: 1px solid #D0B674;
      font-weight: 600;
    }
  }
  @media (max-width: 767px) {
    padding: 0 20px;
  }
}

.btn {
  width: 32px;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid #DFE3E8;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  cursor: pointer;
}

.disabled {
  background: #919EAB;
  opacity: 0.5;
  pointer-events: none;
}

.icon {
  width: 13px;
  color: #C4CDD5;
}

.customSelectPageSize {
  height: 32px;
}
