.container {
  display: flex;
  align-items: center;
  .col {
    flex: 1;
  }
  .arrow {
    width: 16px;
    transform: rotate(180deg);
    margin: 0 8px;
    opacity: 0.6;
  }
}
