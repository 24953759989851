.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  border-bottom: 1px solid #ebebeb;
  .title {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
  }
  .btnClose {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: #f5f5f5;
    }
    .closeIcon {
      width: 18px;
      height: 18px;
    }
  }
}

.linkWrapper {
  position: relative;
  .loader {
    position: absolute;
    right: 10px;
    top: calc(50% + 10px);
    transform: translateY(-50%);
  }

  .linkField {
    padding-right: 30px;
  }
}

.content {
  padding: 20px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  border-bottom: 1px solid #ebebeb;
}

.row {
  display: flex;
  .col {
    flex: 1;
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
}
