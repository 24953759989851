.container {
  display: inline-block;
  margin-left: 10px;
  // position: absolute;
  // right: 2px;
  // top: 18px;
  .icon {
    font-size: 13px;
    opacity: 0.3;
  }
  .iconRight {
    font-size: 13px;
    margin-left: 4px;
    opacity: 0.3;
  }

  .active {
    opacity: 1;
  }
}