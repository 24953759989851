.container {
  display: flex;
  flex-flow: row wrap;
  .option {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
  .search {
    width: 350px;
  }

  .select {
    width: 200px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    padding: 0 20px;
    .option {
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }
    .search {
      width: 100%;
    }
    .select {
      width: 100%;
    }
  }
}
