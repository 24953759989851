.input {
  box-shadow: none !important;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #BFC7CA;
  background-color: rgba(255, 255, 255, 0.25);
  font-size: 16px;
  color: #092631;
  outline: 0;
  padding: 0;
  width: 100%;
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #6e7580;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: #6e7580;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: #6e7580;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: #6e7580;
  }
}

.focus {
  border-bottom-color: #3f6ad8
}

.fullBorder {
  border-radius: 4px;
  box-shadow: inset 0 1px 2px 0 rgba(102, 113, 123, 0.21) !important;
  padding: 0 16px;
  border: 1px solid #e2e5ed;
}

.floatingLabel {
  position: absolute;
  bottom: 8px;
  left: 0;
  color: #999999;
  pointer-events: none;
  // transition: all 0.5s ease-in-out;
  transition: .5s;
  font-size: 12px;
}

.inputContainer {
  position: relative;
  display: inline-flex;
  width: 100%
}

.labelError {
  font-size: 12px;
  color: #CF1E1E !important;
}

.errorMessage {
  font-size: 12px;
  color: #CF1E1E !important;
  margin-left: 6px
}

.dateRangerWrapper {
  position: absolute;
  top: 45px;
  right: 0;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(9, 38, 49, 0.1);
  border: solid 1px rgba(9, 38, 49, 0.1);
  padding-bottom: 20px;
  .actions {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px
  }
}

.dateRangerWrapperLeft {
  background-color: #ffffff;
  right: -300px;
}

.icon {
  width: 10px;
  font-size: 16px;
  margin-right: 10px;
}

.arrowIcon {
  width: 20px;
  height: 20px;
}

.arrowIconUp {
  transform: rotate(180deg);
}
