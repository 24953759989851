.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // background: #fafbfc;
  // box-shadow: 0 0.46875rem 2.1875rem rgba(4,9,20,0.03), 0 0.9375rem 1.40625rem rgba(4,9,20,0.03), 0 0.25rem 0.53125rem rgba(4,9,20,0.05), 0 0.125rem 0.1875rem rgba(4,9,20,0.03);
  height: 60px;
  transition: all .2s;
  background: #FFFFFF;
  box-shadow: 0px 5px 19px rgba(90, 97, 105, 0.12), 0px 2px 0px #E1E5EB;
  .menus {
    position: relative;
    padding: 10px 0;
    margin-right: 51px;
    .menu {
      cursor: pointer;
      span {
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        white-space: nowrap;
      }
      .arrowDown {
        margin-left: 8px;
        transition: all 0.3s;
      }
    }
    .subMenus {
      position: absolute;
      box-shadow: 0 0.46875rem 2.1875rem rgba(4,9,20,0.03), 0 0.9375rem 1.40625rem rgba(4,9,20,0.03), 0 0.25rem 0.53125rem rgba(4,9,20,0.05), 0 0.125rem 0.1875rem rgba(4,9,20,0.03);
      border-width: 0;
      transition: all .2s;
      background-color: #fff;
      border-radius: 4px;
      display: none;
      top: 40px;
      .link {
        display: block;
        padding: 13px 0 13px 16px;
        width: 100%;
        white-space: nowrap;
        &:hover {
          background-color: #f5f5f5;
          text-decoration: none;
        }
      }
    }

    &:hover {
      .subMenus {
        display: block;
      }
      .menu {
        .arrowDown {
          transform: rotate(-180deg);
        }
      }
    }
  }
  .logo {
    width: 147px;
  }
  @media (max-width: 767px) {
    padding: 0;
  }
  .dropdownItem {
    padding: 6px 0;
    display: block;
    font-size: 14px;
    font-weight: 500;
  }
  .left {
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 280px;
    transition: width .2s;
    .logoIcon {
      width: 120px;
    }
    .title {
      font-size: 24px;
      font-weight: 900;
      color: #333333;
      margin-bottom: 0;
      text-transform: uppercase;
    }
  }
  .leftCollapse {
    width: 80px;
    justify-content: center;
    .logo {
      display: none;
    }
  }
  .middle {
    flex: 1;
    display: flex;
    .menuWrapper {
      margin-right: 30px;
      margin-top: 15px;
      width: 40px;
    }
    .btnMenu {
      width: 40px;
      height: 40px;
      border: 1px solid #dddddd;
      border-radius: 4px;
      justify-content: center;
      display: flex;
      align-items: center;
      cursor: pointer;
      .icon {
        font-size: 19px;
        color: #000000;
        width: 26px
      }
      span {
        font-size: 14px;
        color: #000000;
      }
    }
    .menu {
      display: flex;
      align-items: center;
      padding: 24px 30px 18px 30px;
      margin-right: 8px;
      .icon {
        width: 16px;
        margin-right: 8px;
      }
      .faFile {
        width: 12px;
        color: #333333;
        margin-right: 8px;
      }
      span {
        font-size: 14px;
        font-weight: bold;
        color: #999999
      }
    }

    .active {
      position: relative;
      background-image: linear-gradient(to bottom, rgba(82,163,252, 0.1), rgba(82, 163, 252, 0.1) 69%);
      span {
        color: #486b94;
        font-weight: bold;
      }
      &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 2px;
        background-color: #486b94;
        bottom: 0px;
        left: 0;
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    padding-right: 30px;
    .link {
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      margin-right: 51px;

    }
    .active {
      position: relative;
      font-weight: 500;
      background: rgba(222, 184, 86, 0.4) !important;
    }
    .activeMenu {
      position: relative;
      font-weight: bold;
      &:after {
        position: absolute;
        content: '';
        bottom: -20px;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: #CFB675;
        
      }
    }
    .time {
      font-size: 13px;
      font-weight: bold;
      color: #5085a5;
      margin-bottom: 0;
      margin-right: 60px;
    }
    .notification {
      margin-right: 16px;
      display: flex;
      align-items: center;
      position: relative;
      width: 32px;
      height: 32px;
      background-color: #f5f5f5;
      border-radius: 50%;
      justify-content: center;
      .unreaded {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #ef4d1d;
        position: absolute;
        top: 0;
        right: 0
      }
      .bell {
        width: 14px;
        color: #333333;
      }
    }
    .userName {
      display: flex;
      align-items: center;
      cursor: pointer;
      .info {
        .name {
          font-size: 14px;
          font-weight: bold;
          color: #495057;
          margin-bottom: 0;
          opacity: 0.8;
        }
        .accountType {
          font-size: 12px;
          color: #495057;
          margin-bottom: 0;
          opacity: .5;
        }
      }
      .avatar {
        width: 32px;
        height: 32px;
        margin-right: 8px;
      }
    }
  }

  .headerMenu {
    display: none;
  }

  @media (max-width: 1024px) {
    .left {
      display: none;
    }
    .right {
      display: none;
    }
    .middle {
      display: none;
    }
    .headerMenu {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      flex: 1;
      .menu {
        display: flex;
        align-items: center;
        .icon {
          width: 20px;
          margin-right: 5px;
        }
        span {
          font-size: 14px;
          color: #333333;
        }
      }
      .logoIcon {
        width: 80px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
      .logout {
        font-size: 14px;
        color: #CFB675 !important;
      }
    }
  }
}
